<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            Add Activity
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{
            tableSettings.currentPage * tableSettings.perPage -
              tableSettings.perPage +
              (data.index + 1)
          }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? "Yes" : "No" }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowDetailForm(row.item)"
            >
              View Detail
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-activity-form"
      scrollable
      no-close-on-backdrop
      title="New Activity"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="dealer"
                vid="partner"
                rules="required"
              >
                <b-form-group>
                  <label for="partner"><strong>Dealer</strong></label>
                  <v-select
                    id="partner"
                    v-model="selected.partner"
                    :options="list.partners"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="(state.busy || state.locating || activity.location === false)"
                    label="partner_name"
                  >
                    <template #option="{ partner_name }">
                      <strong>{{ partner_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Dealers
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="activities"
                vid="activities"
                rules="required"
              >
                <b-form-group>
                  <label for="activities"><strong>Activities</strong></label>
                  <v-select
                    id="activities"
                    v-model="selected.activities"
                    :options="list.activities"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="(state.busy || state.locating || activity.location === false)"
                    label="activity_name"
                    multiple
                  >
                    <template #option="{ activity_name }">
                      <strong>{{ activity_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Activities
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="latitude"
                vid="latitude"
                rules="required"
              >
                <b-form-group>
                  <label for="latitude">
                    <strong>Latitude</strong>
                  </label>
                  <b-input
                    id="latitude"
                    v-model="activity.latitude"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    disabled
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="longitude"
                vid="longitude"
                rules="required"
              >
                <b-form-group>
                  <label for="longitude">
                    <strong>Longitude</strong>
                  </label>
                  <b-input
                    id="longitude"
                    v-model="activity.longitude"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    disabled
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              style="width: 450px; height: 300px; scroll-behavior: auto;"
              class="mb-2 text-center"
            >
              <MglMap
                ref="map"
                :access-token="mapBox.token"
                :map-style.sync="mapBox.style"
                :center="[geolocation.longitude, geolocation.latitude]"
                :min-zoom="mapBox.minZoom"
              >
                <MglMarker
                  :coordinates="[geolocation.longitude, geolocation.latitude]"
                  color="red"
                />
              </MglMap>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required|max:500"
              >
                <b-form-group>
                  <label for="remarks">
                    <strong>Remarks</strong>
                  </label>
                  <b-textarea
                    id="remarks"
                    v-model="activity.remarks"
                    :state="errors.length > 0 ? false : null"
                    rows="6"
                    max-rows="8"
                    placeholder="enter remarks"
                    autocomplete="off"
                    :disabled="(state.busy || state.locating || activity.location === false)"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label><strong>Photo Attachments</strong></label>
                <b-form-file
                  v-model="files"
                  accept=".jpg, .jpeg, .png"
                  multiple
                  class="mb-1"
                  :disabled="state.busy"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button
                variant="success"
                :disabled="state.uploadingFile || files.length < 1"
                class="mr-1"
                size="sm"
                @click="onUploadAttachment"
              >
                {{ state.uploadingFile ? 'Uploading Photos...' : 'Upload Photos' }}
              </b-button>
              <b-button
                variant="danger"
                :disabled="activity.attachments.length < 1"
                size="sm"
                @click="onRemoveAllFile"
              >
                {{ state.removingFile ? 'Removing Photos...' : 'Remove Photos' }}
              </b-button>
            </b-col>

            <b-col
              cols="12"
              class="mt-2"
            >
              <b-table
                v-if="activity.attachments.length > 0"
                class="mt-1"
                :fields="attachmentTableSettings.fields"
                :items="activity.attachments"
                responsive
                striped
                hover
              >
                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      class="mr-1"
                      :disabled="state.removingFile"
                      @click="onPreviewAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                    <b-button
                      v-if="!state.viewMode"
                      size="sm"
                      :disabled="state.removingFile"
                      @click="onRemoveFile(row.item)"
                    >
                      Remove
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="(state.busy || state.locating || activity.location === false || activity.attachments.length < 1)"
          @click="ok()"
        >
          Submit
        </b-button>
        <b-button
          variant="danger"
          :disabled="(state.busy || state.locating)"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!--modal-->
    <b-modal
      id="modal-view-activity"
      scrollable
      no-close-on-backdrop
      title="View Details"
    >
      <form
        role="form"
        novalidate
        @submit.prevent
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group>
              <label for="view_partner"><strong>Dealer</strong></label>
              <b-input
                id="view_partner"
                type="text"
                :value="activity.partner_name"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group>
              <label for="view_activities"><strong>Activities</strong></label>
              <b-input
                id="view_activities"
                type="text"
                :value="activity.activities"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <label for="view_longitude"><strong>Longitude</strong></label>
              <b-input
                id="view_longitude"
                type="text"
                :value="activity.longitude"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <label for="view_latitude"><strong>Latitude</strong></label>
              <b-input
                id="view_latitude"
                type="text"
                :value="activity.latitude"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <!-- <b-col
            cols="12"
            style="width: 450px; height: 300px; scroll-behavior: auto;"
            class="mb-2 text-center"
          >
            <MglMap
              ref="map"
              :access-token="mapBox.token"
              :map-style.sync="mapBox.style"
              :center="[geolocation.longitude, geolocation.latitude]"
              :min-zoom="mapBox.minZoom"
            >
              <MglMarker
                :coordinates="[geolocation.longitude, geolocation.latitude]"
                color="red"
              />
            </MglMap>
          </b-col> -->

          <b-col cols="12">
            <b-form-group>
              <label for="remarks"><strong>Remarks</strong></label>
              <b-textarea
                id="remarks"
                :value="activity.remarks"
                rows="6"
                max-rows="8"
                placeholder="enter remarks"
                autocomplete="off"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-2"
          >
            <b-table
              :fields="attachmentTableSettings.fields"
              :items="activity.attachments"
              responsive
              striped
              hover
            >
              <template #cell(actions)="row">
                <div class="text-nowrap">
                  <b-button
                    size="sm"
                    class="mr-1"
                    :disabled="state.removingFile"
                    @click="onPreviewAttachment(row.item)"
                  >
                    Preview
                  </b-button>
                </div></template>
            </b-table>
          </b-col>

        </b-row>
      </form>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!--modal-->
    <b-modal
      id="modal-preview-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${preview.file_name}]`"
      size="lg"
    >
      <b-img
        fluid
        :src="preview.image"
      />

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, DSMActivityService, DSMAttachmentService, SharedListService } from '@/services'
import { MglMap, MglMarker } from 'vue-mapbox'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'
import misc from '@/mixins/misc'

export default {
  name: 'DSMActivities',

  middleware: ['auth', 'dsm'],

  metaInfo () {
    return {
      title: 'My Activities'
    }
  },

  components: {
    MglMap,
    MglMarker
  },

  mixins: [formatter, url, misc],

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        viewMode: false,
        locating: false,
        uploadingFile: false,
        removingFile: false
      },
      list: {
        partners: [],
        activities: []
      },
      geolocation: {
        longitude: 121.5884777368,
        latitude: 16.696369223777
      },
      files: [],
      preview: {
        file_name: '',
        image: ''
      },
      selected: {
        partner: null,
        activities: []
      },
      mapBox: {
        token: 'pk.eyJ1IjoiYWxqYXlzb2x1dGlvbnMiLCJhIjoiY2xoazZyNnY5MG9zajNkcXZydjlsaGtmZCJ9.jQRISo_vXAhzY7Gq2p_H2w',
        style: 'mapbox://styles/mapbox/outdoors-v12',
        minZoom: 10
      },
      activity: {
        id: 0,
        active: 0,
        partner: null,
        partner_name: '',
        activities: [],
        location: false,
        longitude: 121.5884777368,
        latitude: 16.696369223777,
        remarks: '',
        attachment_path: '',
        attachments: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', label: 'activity date', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'partner_name' },
          { key: 'activities', label: 'activities', formatter: this.formatActivities },
          { key: 'latitude' },
          { key: 'longitude' }
        ]
      },
      attachmentTableSettings: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      }
    }
  },

  computed: {
    coordinates () {
      return [this.activity.longitude, this.activity.latitude]
    }
  },

  watch: {
    'selected.partner' (value) {
      this.activity.partner = value?.id || null
    },

    'selected.activities' (value) {
      this.activity.activities = value
    }
  },

  mounted () {
    core.index()
    this.getPartners()
    this.getActivities('existing_partner')
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await DSMActivityService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getPartners () {
      await SharedListService.getPartners().then(({ data }) => {
        this.list.partners = data
      })
    },

    async getActivities (tag) {
      await SharedListService.getActivities(`tag=${tag}`).then(({ data }) => {
        this.list.activities = data
      })
    },

    formatActivities (activities) {
      return activities ? activities?.map(item => item.activity_name).toString() : null
    },

    onShowDetailForm (current) {
      this.state.viewMode = true
      this.activity.id = current.id
      this.activity.partner_name = current.partner_name
      this.activity.longitude = current.longitude
      this.activity.latitude = current.latitude
      this.activity.activities = current.activities?.map(item => item.activity_name).toString()
      this.activity.attachment_path = current.attachment_path
      this.activity.attachments = current.attachments
      this.activity.remarks = current.remarks
      this.$bvModal.show('modal-view-activity')
    },

    async onShowAddForm () {
      this.state.viewMode = false
      this.selected.partner = null
      this.selected.activities = []
      this.activity.id = 0
      this.activity.active = 1
      this.location = false
      this.activity.longitude = this.geolocation.longitude
      this.activity.latitude = this.geolocation.latitude
      this.activity.attachment_path = ''
      this.activity.attachments = []
      this.activity.remarks = ''
      this.$bvModal.show('modal-activity-form')

      if (this.isMobile()) {
        this.state.locating = true
        await this.getGeoLocation().then(({ position }) => {
          this.state.locating = false
          this.activity.location = true
          this.activity.longitude = position.longitude
          this.activity.latitude = position.latitude
          this.geolocation.longitude = position.longitude
          this.geolocation.latitude = position.latitude
        }).catch(({ message }) => {
          this.state.locating = false
          setTimeout(() => {
            if (this.$refs.form) {
              this.$refs.form.setErrors({
                latitude: [message],
                longitude: [message]
              })
            }
          }, 100)
        })
      } else {
        setTimeout(() => {
          if (this.$refs.form) {
            this.$refs.form.setErrors({
              latitude: ['Unsupported Device'],
              longitude: ['Unsupported Device']
            })
          }
        }, 100)
      }
    },

    onShowEditForm (current) {
      // this.state.editing = true
      // this.account.id = account.id
      // this.account.email = account.email
      // this.account.mobile_number = account.mobile_number
      // this.account.owner_name = account.owner_name
      // this.account.home_address = account.home_address
      // this.account.store_name = account.store_name
      // this.account.store_address = account.store_address
      // this.$bvModal.show('modal-activity-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to submit activity?'

          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPostSubmit()
              // if (this.state.editing) {
              //   return this.onFormPutSubmit()
              // } else {
              //   return this.onFormPostSubmit()
              // }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          }
          )
        }
      })
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        DSMActivityService.post(this.activity).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-activity-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        DSMActivityService.put(this.activity).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-activity-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.account.id })
            row.updated_at = data.account.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onUploadAttachment () {
      const formBody = new FormData()

      let counter = 0

      this.files.forEach(file => {
        formBody.append(`file[${counter}]`, file)
        counter++
      })

      formBody.append('file_path', this.activity.attachment_path)

      this.state.uploadingFile = true

      await DSMAttachmentService.upload(formBody).then(response => {
        this.activity.attachment_path = response.data.attachment_path
        this.activity.attachments.push(...response.data.attachments)
        this.state.uploadingFile = false
        this.files = []
      }).catch(() => {
        this.files = []
        this.state.uploadingFile = false
      })
    },

    async onRemoveFile (file) {
      this.state.removingFile = true

      await DSMAttachmentService.removeFile(this.activity.attachment_path, file.file_hash_name).then(() => {
        this.activity.attachments = _.reject(this.activity.attachments, currentObject => {
          return currentObject.file_hash_name === file.file_hash_name
        })
        this.state.removingFile = false
      }).catch(() => {
        this.state.removingFile = false
      })
    },

    async onRemoveAllFile () {
      this.state.removingFile = true

      await DSMAttachmentService.removeAllFile(this.activity.attachment_path).then(() => {
        this.activity.attachment_path = ''
        this.activity.attachments = []
        this.state.removingFile = false
      }).catch(() => {
        this.files = []
        this.state.removingFile = false
      })
    },

    async onPreviewAttachment (item) {
      if (this.state.viewMode) {
        this.preview.file_name = item.file_original_name
        this.preview.image = this.getImageUrl(`${this.activity.attachment_path}/${item.file_hash_name}`)
        this.$bvModal.show('modal-preview-image')
      } else {
        this.preview.file_name = item.file_original_name
        this.preview.image = this.getImageUrl(`uploads/${this.activity.attachment_path}/${item.file_hash_name}`)
        this.$bvModal.show('modal-preview-image')
      }
    }
  }
}
</script>
